@use 'sass:color';

// Dependent colors
$black: #000000;
$white: #ffffff;

$classic-base-color: #282c37;
$classic-primary-color: #bdacbb;
$classic-secondary-color: #e0e0d8;
$classic-highlight-color: #2c7130;

$blurple-600: #3b953f; // Deeper green
$blurple-500: #1dac1b; // Lighter than that
$blurple-300: #94e47c; // Urgh blurple is the worst
$grey-600: #4e4c5a; // Trout
$grey-100: #dadaf3; // Topaz

// Differences
$success-green: lighten(hsl(138deg, 32%, 35%), 8%);

$base-overlay-background: $white !default;
$valid-value-color: $success-green !default;

$ui-base-color: $classic-secondary-color !default;
$ui-base-lighter-color: #6abf69;
$ui-primary-color: #c3cec3;
$ui-secondary-color: $classic-base-color !default;
$ui-highlight-color: $classic-highlight-color !default;

$ui-button-secondary-color: $grey-600 !default;
$ui-button-secondary-border-color: $grey-600 !default;
$ui-button-secondary-focus-color: $white !default;

$ui-button-tertiary-color: $blurple-500 !default;
$ui-button-tertiary-border-color: $blurple-500 !default;

$primary-text-color: $black !default;
$darker-text-color: $classic-base-color !default;
$highlight-text-color: darken($ui-highlight-color, 8%) !default;
$dark-text-color: #5e5066;
$action-button-color: #495542;

$inverted-text-color: $black !default;
$lighter-text-color: $classic-base-color !default;
$light-text-color: #7b807b;

// Newly added colors
$account-background-color: $white !default;

// Invert darkened and lightened colors
@function darken($color, $amount) {
  @return hsl(
    hue($color),
    color.channel($color, 'saturation', $space: hsl),
    color.channel($color, 'lightness', $space: hsl) + $amount
  );
}

@function lighten($color, $amount) {
  @return hsl(
    hue($color),
    color.channel($color, 'saturation', $space: hsl),
    color.channel($color, 'lightness', $space: hsl) - $amount
  );
}

$emojis-requiring-inversion: 'chains';

body {
  --dropdown-border-color: hsl(240deg, 25%, 88%);
  --dropdown-background-color: #fff;
  --modal-border-color: hsl(240deg, 25%, 88%);
  --modal-background-color: var(--background-color-tint);
  --background-border-color: hsl(240deg, 25%, 88%);
  --background-color: #fff;
  --background-color-tint: rgba(255, 255, 255, 80%);
  --background-filter: blur(10px);
  --on-surface-color: #{transparentize($ui-base-color, 0.65)};
}
